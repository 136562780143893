



















































import { Component } from 'vue-property-decorator';
import { BaseView } from '@shared/views/BaseView';
import AboutMenu from '@client/views/common/AboutMenu.vue';
import RecentEntries from '@client/views/blog/RecentEntries.vue';
import ContactWidget from '@client/views/common/ContactWidget.vue';

@Component({
	components: {
		RecentEntries,
		ContactWidget,
		AboutMenu,
	},
})
export default class About extends BaseView {


}

